import { createRouter, createWebHistory } from "vue-router";
import TokenService from "@/services/token.service";
import AdminView from "@/views/Admin/AdminDashboardView.vue";
import AdminViewUsuarios from "@/views/Admin/AdminView.vue";
import ProcedenciaYacimiento from "@/views/Admin/Procedencia/ProcedenciaYacimiento";
import ProcedenciaTranca from "@/views/Admin/Procedencia/ProcedenciaTranca";
import PropietatiosContratista from "@/views/Admin/Propietarios/PropietariosContratistas";
import PropietatiosEmpresa from "@/views/Admin/Propietarios/PropietariosEmpresas";
import PropietatiosCuadrilla from "@/views/Admin/Propietarios/PropietariosCuadrilla";

import TiposVehiculos from "@/views/Admin/Transporte/TransporteTipos";
import MarcasVehiculos from "@/views/Admin/Transporte/TransporteMarcas";
import ColoresVehiculos from "@/views/Admin/Transporte/TransporteColores";
import CategoriasMateriales from "@/views/Admin/Materiales/MaterialesCategorias";
import TiposMateriales from "@/views/Admin/Materiales/MaterialesTipos";
import CooperativaAdmin from "@/views/Admin/Cooperativa/CooperativaAdmin";
import LoginView from "@/views/LoginView.vue";
import ResetPassword from "@/views/ResetPasswordView.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import createAccountView from "@/views/CreateAccount.vue";
import playView from "@/views/PlaygroundView.vue";
import tornaguiaView from "@/views/TornaguiaView.vue";

const routes = [
  {
    path: "/administrador",
    name: "admin",
    component: AdminView,
    meta: {
      title: "Admin",
      requiresSuperUser: true,
    },
  },
  {
    path: "/administrador/usuarios",
    name: "adminUsers",
    component: AdminViewUsuarios,
    meta: {
      title: "Usuarios",
      requiresAdmin: true,
    },
  },

  {
    path: "/administrador/yacimiento",
    name: "adminYacimiento",
    component: ProcedenciaYacimiento,
    meta: {
      title: "Yacimiento",
    },
  },
  {
    path: "/administrador/tranca",
    name: "adminTranca",
    component: ProcedenciaTranca,
    meta: {
      title: "Tranca",
      requiresSuperUser: true,
    },
  },
  {
    path: "/administrador/destino",
    name: "adminEmpresa",
    component: PropietatiosEmpresa,
    meta: {
      title: "Destino",
      requiresSuperUser: true,
    },
  },
  {
    path: "/administrador/contratista",
    name: "adminContratista",
    component: PropietatiosContratista,
    meta: {
      title: "Empresa",
      requiresSuperUser: true,
    },
  },

  {
    path: "/administrador/color-vehiculo",
    name: "adminColorVehiculo",
    component: ColoresVehiculos,
    meta: {
      title: "Color Vehiculo",
      requiresSuperUser: true,
    },
  },

  {
    path: "/administrador/marca-vehiculo",
    name: "adminMarcaVehiculo",
    component: MarcasVehiculos,
    meta: {
      title: "Marca Vehiculo",
      requiresSuperUser: true,
    },
  },

  {
    path: "/administrador/cuadrilla",
    name: "adminCuadrilla",
    component: PropietatiosCuadrilla,
    meta: {
      title: "Cuadrilla",
      requiresSuperUser: true,
    },
  },

  {
    path: "/administrador/tipo-vehiculo",
    name: "adminTipoVehiuclo",
    component: TiposVehiculos,
    meta: {
      title: "Tipo Vehiculo",
      requiresSuperUser: true,
    },
  },

  {
    path: "/administrador/tipo-material",
    name: "adminTipoMaterial",
    component: TiposMateriales,
    meta: {
      title: "Tipo Material",
      requiresSuperUser: true,
    },
  },

  {
    path: "/administrador/material",
    name: "adminCategoriaMaterial",
    component: CategoriasMateriales,
    meta: {
      title: "Categoria Material",
      requiresSuperUser: true,
    },
  },

  {
    path: "/administrador/cooperativa",
    name: "adminCooperativa",
    component: CooperativaAdmin,
    meta: {
      title: "Cooperativa",
      requiresAdmin: true,
    },
  },

  {
    path: "/",
    name: "home",
    component: LoginView,
    meta: {
      title: "Iniciar sesion",
      guestOnly: true,
    },
  },
  {
    path: "/reset-password",
    name: "reset",
    component: ResetPassword,
    meta: {
      title: "Cambiar contraseña",
    },
  },
  {
    path: "/forgot-password",
    name: "forgot",
    component: ForgotPassword,
    meta: {
      title: "Olvido contraseña",
    },
  },
  {
    path: "/create-account",
    name: "createAccount",
    component: createAccountView,
    meta: {
      title: "Create Account",
    },
  },
  {
    path: "/data",
    name: "playView",
    component: playView,
    meta: {
      title: "Create Account",
      requiresAuth: true,
    },
  },
  {
    path: "/tornaguia",
    name: "tornaguiaView",
    component: tornaguiaView,
    meta: {
      title: "Tornaguia",
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | TORNAGUIA`;
  next();
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isGuestOnly = to.matched.some((record) => record.meta.guestOnly);
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);
  const requiresSuperUser = to.matched.some((record) => record.meta.requiresSuperUser);

  const currentUser = TokenService.getUser();
  const role = TokenService.getLocalRole();
  const temp = TokenService.getLocalTemp();

  if (requiresAuth) {
    if (temp && currentUser) {
      return next("/reset-password");
    }
    if (!currentUser) {
      alert('True')
    }
  }
  if(requiresSuperUser) {
    if (!currentUser || (role !== "super_user" && role !== "admin")) {
      return next("/tornaguia");
    }
  }

  if (requiresAdmin ) {
    if (!currentUser || (role !== "admin")) {
      return next("/tornaguia");
    }
  } else if (isGuestOnly && currentUser) {
    return next("/tornaguia");
  }

  return next();
});

export default router;
