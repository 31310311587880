<template>
  <div>
    <ProgressSpinner
      v-if="this.loading"
      style="width: 50px; height: 50px"
      strokeWidth="8"
      fill="var(--surface-ground)"
      animationDuration=".5s"
      aria-label="Custom ProgressSpinner"
    />
    <div class="tornaguia" v-else>
      <div class="flex">
        <Button icon="pi pi-th-large" label="Cambiar Cooperativa" @click=OpenChangeModal v-if="this.userRole == 'admin'"></Button>
        <Dialog v-model:visible="changeCompany" modal header="Cambiar Cooperativa" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
          <div class="grid grid-cols-2 gap-3" >
            <div class="cursor-pointer mb-2 font-medium flex flex-col p-3 rounded bg-gray-100 hover:bg-gray-300 items-center" v-for="(item, index) in cooperativas" :key="index" @click="changeCooperativa(item)">
              <i class="pi pi-building" style="font-size: 2.5rem"></i>
              <p>{{ item.label }}</p>

            </div>

          </div>

</Dialog>

      </div>
      <div class="grid grid-cols-2 py-3">
        <div class="flex flex-col">
          <div class="flex items-center">
            <p class="font-bold">Nombre del encargado: </p>
            <p>{{ this.userName }}</p>
          </div>
          <div class="flex items-center">
            <p class="font-bold">Cooperativa: </p>
            <p>{{ this.cooperativaName }}</p>
          </div>
          <div class="flex items-center">
            <p class="font-bold">Carnet de identidad:</p>
            <p>{{ this.idNumber }}</p>
          </div>
          <div class="flex items-center">
            <p class="font-bold">Fecha:</p>
            <p>{{ this.today }}</p>
          </div>
        </div>
        <div class="flex flex-col">
          <InputText
            v-model="numeroUnico"
            type="text"
            placeholder="Numero Unico"
            disabled
          />
        </div>
      </div>
      <div class="grid grid-cols-2 gap-1">
        <!-- procedencia -->
        <div class="flex flex-col">
          <div class="bg-neutral-two rounded p-1">
            <h6 class="text-white">PROCEDENCIA</h6>
          </div>
          <!-- contenido -->
          <div class="p-3">
            <div class="flex items-center justify-around mb-2">
              <div class="flex items-center">
                <h6 class="font-bold mr-2">Departamento:</h6>
                <Dropdown
                  v-model="selectedDepartamento"
                  :options="departamento"
                  optionLabel="label"
                  placeholder="Departamento"
                  class="w-full md:w-14rem"
                />
              </div>
              <div class="flex items-center">
                <h6 class="font-bold mr-2">Municipio:</h6>
                <Dropdown
                  v-model="selectedMunicipio"
                  :options="municipio"
                  optionLabel="label"
                  placeholder="Municipio"
                  class="w-full md:w-14rem"
                />
              </div>
            </div>

            <div class="flex flex-col space-y-4">
              <Dropdown
                v-model="selectedYacimiento"
                :options="yacimiento"
                optionLabel="label"
                placeholder="Yacimiento"
                class="w-full md:w-14rem"
              />
              <Dropdown
                v-model="selectedTranca"
                :options="trancas"
                optionLabel="label"
                placeholder="Tranca de Control"
                class="w-full md:w-14rem"
              />
            </div>
          </div>
        </div>
        <!-- Propietario -->
        <div class="flex flex-col">
          <div class="bg-neutral-two rounded p-1">
            <h6 class="text-white uppercase">Propietario del mineral</h6>
          </div>
          <!-- contenido -->
          <div class="p-3">
            <div class="flex items-center mb-2">
              <h6 class="font-bold">{{ this.cooperativaName }}</h6>
            </div>

            <div class="flex flex-col space-y-4">
              <Dropdown
                v-model="selectedDestino"
                :options="destino"
                optionLabel="label"
                placeholder="Empresa Destino"
                class="w-full md:w-14rem"
              />
              <Dropdown
                v-model="selectedContratista"
                :options="contratista"
                optionLabel="label"
                placeholder="Contratista"
                class="w-full md:w-14rem"
              />
              <Dropdown
                v-model="selectedCuadrilla"
                :options="cuadrilla"
                optionLabel="label"
                placeholder="Cuadrilla"
                class="w-full md:w-14rem"
              />
            </div>
          </div>
        </div>
        <!-- medio de transporte -->
        <div class="flex flex-col">
          <div class="bg-neutral-two rounded p-1">
            <h6 class="text-white uppercase">medio de transporte</h6>
          </div>
          <!-- contenido -->
          <div class="p-3">
            <div class="flex flex-col space-y-4">
              <Dropdown
                v-model="selectedTipo"
                :options="tipoVehiculo"
                optionLabel="label"
                placeholder="Tipo"
                class="w-full md:w-14rem"
              />
              <Dropdown
                v-model="selectedMarca"
                :options="marca"
                optionLabel="label"
                placeholder="Marca"
                class="w-full md:w-14rem"
              />
              <Dropdown
                v-model="selectedColor"
                :options="color"
                optionLabel="label"
                placeholder="Color"
                class="w-full md:w-14rem"
              />
              <InputText v-model="inputPlaca" type="text" placeholder="Placa" />
            </div>
          </div>
        </div>

        <div class="flex flex-col">
          <div class="bg-neutral-two rounded p-1">
            <h6 class="text-white uppercase">tipo de material</h6>
          </div>
          <!-- contenido -->
          <div class="p-3">
            <div class="flex flex-col space-y-4">
              <div class="flex flex-wrap gap-3">
                <div
                  class="flex items-center"
                  v-for="(item, index) in tipos_material"
                  :key="index"
                >
                  <RadioButton
                    v-model="tipoMaterial"
                    inputId="ingredient1"
                    :name="item.label"
                    :value="item.value"
                  />
                  <label for="ingredient1" class="ml-2">{{ item.label }}</label>
                </div>
              </div>
              <MultiSelect
                v-model="selectedMaterial"
                display="chip"
                :options="material"
                optionLabel="label"
                placeholder="Materiales"
                :maxSelectedLabels="4"
                class="w-full md:w-20rem"
              />
              <div class="grid grid-cols-2 gap-1">
                <InputText
                  v-model="inputPeso"
                  type="text"
                  placeholder="Peso en Toneladas"
                />
                <InputText
                  v-model="inputCantidad"
                  type="text"
                  placeholder="Cantidad de Sacos"
                />
              </div>

              <InputText
                v-model="inputLote"
                type="text"
                placeholder="No. de Lote"
                disabled
              />
            </div>
          </div>
        </div>
      </div>

      <Button @click="createTornaguia" v-if="!this.loadingAdd"
        >Crear Tornaguia</Button
      >
      <ProgressSpinner
        v-if="this.loadingAdd"
        style="width: 50px; height: 50px"
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
        aria-label="Custom ProgressSpinner"
      />
    </div>
  </div>
</template>
<script>
import tokenService from "@/services/token.service";
import tornaguiaService from "@/services/tornaguia.service";
import metadataService from "@/services/metadata.service";
import moment from "moment";
export default {
  name: "TornaguiaForm",

  data() {
    return {
      loading: null,
      loadingAdd: null,
      metaData: null,
      selectedMunicipio: null,
      selectedDepartamento: null,
      selectedMaterial: null,
      selectedCuadrilla: null,
      tipoMaterial: null,
      selectedYacimiento: null,
      selectedTranca: null,
      selectedDestino: null,
      selectedContratista: null,
      selectedTipo: null,
      selectedMarca: null,
      selectedColor: null,
      inputPlaca: null,
      inputPeso: null,
      inputCantidad: null,
      inputLote: null,
      numeroUnico: Date.now(),
      changeCompany:null,
      cooperativas: null,
    };
  },
  mounted() {
    this.getMetaData();
  },
  computed: {
    userName() {
      return tokenService.getLocalName();
    },
    idNumber() {
      return tokenService.getLocalID();
    },
    user() {
      return this.userCompany? this.userCompany: tokenService.getLocalCompany();
    },
    cooperativaName() {
      return this.userCompanyName? this.userCompanyName: tokenService.getLocalCompanyName();
    },
    today() {
      moment.locale("es");
      return moment().format("MM-DD-YYYY");
    },
    userId() {
      return tokenService.getLocalUserID();
    },
    userCompany() {
      return this.$store.state.userCompany
    },
    userCompanyName() {
      return this.$store.state.userCompanyName
    },
    userRole() {
      return tokenService.getLocalRole();
    },
  },
  methods: {
    OpenChangeModal() {
      this.changeCompany = !this.changeCompany
    },

    changeCooperativa(item) {
      let id = item.value
      let name = item.label
      this.$store.commit('updateUserCompany', id)
      this.$store.commit('updateUserCompanyName', name)
      this.getMetaData()
      this.OpenChangeModal()
    },
    async getMetaData() {
      this.loading = true;
      await this.getCooperativas();

      try {
        let meta = await metadataService.get_meta_items(this.user)
        console.log('meta', meta)


        this.yacimiento = this.mapResurce(meta, "yacimiento");
        this.destino = this.mapResurce(meta, "destino");
        this.tipoVehiculo = this.mapResurce(meta, "tipo_vehiculo");
        this.marca = this.mapResurce(meta, "marca_vehiculo");
        this.trancas = this.mapResurce(meta, "tranca");
        this.contratista = this.mapResurce(meta, "contratista");
        this.material = this.mapResurce(meta, "material");
        this.color = this.mapResurce(meta, "color_vehiculo");
        this.departamento = this.mapResurce(meta, "departamento");
        this.municipio = this.mapResurce(meta, "municipio");
        this.tipos_material = this.mapResurce(meta, "tipo_material");
        this.cuadrilla = this.mapResurce(meta, "cuadrilla");
      } catch (error) {
        console.error("Error fetching metadata:", error);
        // Handle the error appropriately here
      } finally {
        this.loading = false;
      }
    },
    async getCooperativas() {
      try {
        await this.$store.dispatch("get_cooperativas");
        let cooperativas = await this.$store.state.cooperativas;
        this.cooperativas = this.mapCooperativa(cooperativas);
      } catch (error) {
        console.error("Error fetching metadata:", error);
        // Handle the error appropriately here
      } 
    },

    mapCooperativa(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.nombre,
      }));
    },

    mapResurce(meta, key) {
      return meta[key].map((item) => ({
        value: item.id,
        label: item.nombre,
      }));
    },

    async createTornaguia() {
      let now = new Date();
      let formattedDate = now.toISOString();
      let material = this.selectedMaterial.map((item) => item.value);
      this.loadingAdd = true;
      await tornaguiaService
        .create(
          formattedDate,
          String(this.numeroUnico),
          String(this.inputCantidad?this.inputCantidad : 0),
          String(this.inputCantidad?this.inputCantidad : 0),
          String(this.numeroUnico),
          String(this.inputPlaca),
          this.user,
          this.selectedDepartamento?.value,
          this.selectedMunicipio?.value,
          this.selectedYacimiento?.value,
          this.selectedTranca?.value,
          this.selectedDestino?.value,
          this.selectedContratista?.value,
          material[0],
          this.tipoMaterial,
          this.selectedTipo?.value,
          this.selectedColor?.value,
          this.selectedMarca?.value,
          this.selectedCuadrilla?.value,
          this.userId
        )
        .then(() => {
          this.$store.dispatch("get_tornaguias", this.userId);
          this.loadingAdd = false;
        });
    },
  },
};
</script>
<style lang=""></style>
