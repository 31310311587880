<template>
  <div id="slim-bar" class="slim-bar" :class="{ 'expanded-nav': isClicked }">
    <div class="navbar-container">
      <transition name="fade" mode="out-in">
        <div
          class="flex items-center justify-between"
          @click="toggleBar"
          ref="toggle"
        >

          <div class="p-3 rounded cursor-pointer btn-nav">
            <i class="pi pi-bars" style="color: #fff"></i>
          </div>
          <h6 class="text-white" v-if="isClicked">{{ this.cooperativaName }}</h6>
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div class="flex flex-col">
          <ul
            class="md:flex-col md:min-w-full flex flex-col list-none"
            v-if="!isClicked"
          >
            <li
              class="flex"
              v-for="(item, index) in NavItems"
              :key="index"
              v-tooltip="item.label"
            >
              <router-link
                :to="{ name: item.route_name }"
                class="mb-4 w-full img-pop rounded py-2 px-4"
                :class="{ active: this.route === item.route_name }"
              >
                <div class="flex items-center text-white">
                  <i :class="[item.icon, { 'mr-2': isClicked }]"></i>

                  <h6 v-if="isClicked">{{ item.label }}</h6>
                </div>
              </router-link>
            </li>
          </ul>
          <ul class="md:flex-col md:min-w-full flex flex-col list-none" v-else>
            <li class="flex" v-for="(item, index) in NavItems" :key="index">
              <router-link
                :to="{ name: item.route_name }"
                class="mb-4 w-full img-pop rounded py-2 px-4"
                :class="{ active: this.route === item.route_name }"
              >
                <div class="flex items-center text-white">
                  <i :class="[item.icon, { 'mr-2': isClicked }]"></i>

                  <h6 v-if="isClicked">{{ item.label }}</h6>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </transition>
      <transition name="fade" mode="out-in">
        <div class="profileTag flex flex-col text-white">
          <h5
            class="cursor-pointer hover:text-red-300"
            v-tooltip="'Perfil'"
            v-if="!isClicked"
          >
            {{ this.userName }}
          </h5>
          <h5 class="cursor-pointer hover:text-red-300" v-else>
            {{ this.userName }}
          </h5>

          <hr />
          <div
            class="flex items-center justify-center py-2 cursor-pointer hover:text-red-300"
            @click="logout"
          >
            <i class="pi pi-sign-out" :class="{ 'mr-2': isClicked }"></i>
            <p v-if="isClicked">Cerrar Sesion</p>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import getEnv from "@/utils/env";
import tokenService from "@/services/token.service";
import EventBus from "@/common/EventBus";
export default {
  name: "NavigationBar",
  props: ["NavItems"],
  data() {
    return {
      isClicked: false,
    };
  },
  methods: {
    toggleBar() {
      this.isClicked = !this.isClicked;
    },
    reformat(str) {
      let name = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

      return name.match(/(\b\S)?/g).join("");
    },
    logout() {
      EventBus.dispatch("logout");
    },
  },
  mounted() {
    if (this.id) {
      this.$store.dispatch("get_meta_data", this.id);
    }
  },
  computed: {
    id() {
      return tokenService.getLocalCompany();
    },
    route() {
      return this.$route.name;
    },
    customerName() {
      return getEnv("VUE_APP_CUSTOMER_NAME");
    },
    cooperativaName() {
      return tokenService.getLocalCompanyName();
    },
    userName() {
      return this.reformat(tokenService.getLocalName());
    },
  },
};
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.slim-bar {
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #130f40;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 998;
  padding: 2rem 20px 2rem 20px;
  width: 90px;
  border-radius: 5px;
  transition: width 0.15s ease-in-out;

  .btn-nav {
    display: flex;
    align-items: center;
    &:hover {
      background-color: rgba(242, 242, 242, 0.1);
    }
    i {
      font-size: 1.5rem;
    }
  }

  .navbar-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    z-index: 999;
    .active {
      background-color: rgba(242, 242, 242, 0.1);
    }
  }

  .profileTag {
    background-color: rgba(242, 242, 242, 0.1);
  }
}

.slim-bar.expanded-nav {
  width: 300px;
}
</style>
