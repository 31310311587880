<template>
  <div>
    <!-- Add modal -->
    <Dialog
      v-model:visible="addModal"
      modal
      :header="Agregar"
      :style="{ width: '50vw' }"
    >
      <form>
        <h6>Agregar {{ this.type }}</h6>
        <div class="inputs mb-3">
          <div class="input">
            <InputText
              v-model="nombreRecurso"
              type="text"
              placeholder="Nombre"
            />
          </div>
        </div>
        <div class="inputs mb-3">
          <div class="input">
            <InputText
              v-model="cooperativa"
              disabled
              :placeholder="user.cooperativa_info['nombre']"
              v-if="(this.userRole == 'user') | (this.userRole == 'super_user')"
            />
            <Dropdown
              v-if="this.userRole == 'admin' && this.cooperativas"
              v-model="cooperativa"
              :options="cooperativas"
              optionLabel="label"
              placeholder="Cooperativa"
              class="w-full md:w-14rem"
            />
          </div>
        </div>
        <div class="inputs mb-3">
          <div class="input">
            <InputText v-model="tipo" disabled :placeholder="this.type" />
          </div>
        </div>
        <Button
          label="Agregar"
          icon="pi pi-plus"
          rounded
          raised
          @click="addData()"
        ></Button>
      </form>
    </Dialog>
    <!-- Delete Modal -->
    <Dialog
      v-model:visible="deleteModal"
      modal
      header="Confirmacion"
      :style="{ width: '50vw' }"
    >
      <h6>¿Esta seguro que quiere eliminar esta fila?</h6>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          @click="this.deleteModal = !this.deleteModal"
          text
        />
        <Button
          label="Si"
          icon="pi pi-check"
          @click="confirmDeletion(this.toBeDeleted)"
          autofocus
        />
      </template>
    </Dialog>
    <!-- Edit Modal -->
    <Dialog
      v-model:visible="editModal"
      modal
      header="Editar"
      :style="{ width: '50vw' }"
    >
      <div class="input">
        <InputText v-model="newName" :placeholder="toBeEdited['nombre']" />
      </div>

      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          @click="this.deleteModal = !this.deleteModal"
          text
        />
        <Button
          label="Si"
          icon="pi pi-check"
          @click="confirmEdit(this.toBeEdited)"
          autofocus
        />
      </template>
    </Dialog>

    <Modal
      v-if="modalActive"
      :modalMessage="this.modalMsg"
      :type="'user'"
      @close-modal="showModal()"
      @confirmDelete="deleteUser()"
    />

    <grid>
      <template #content>
        <goBack />
        <ProgressSpinner
          v-if="this.loading"
          style="width: 50px; height: 50px"
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".5s"
          aria-label="Custom ProgressSpinner"
        />
        <div v-else>
          <div class="flex justify-between items-center mb-6">
            <h5>Administrador - Propietario Mineral</h5>
          </div>
          <hr class="mb-12" />
          <TableComponent
            @openModal="openAddModal"
            @confirmDelete="confirmDeleteSelected"
            @confirmEdit="confirmEditSelected"
            :columns="this.columns"
            :values="this.values"
            :tableTitle="'Manejar Cuadrillas'"
          />
        </div>
      </template>
    </grid>
  </div>
</template>
<script>
import grid from "@/layouts/Layout.vue";
import Modal from "@/components/Modal";
import TableComponent from "@/components/DataComponentGrid.vue";
import CuadrillaService from "@/services/cuadrilla.service";
import tokenService from "@/services/token.service";
import goBack from "@/components/resources/GoBack.vue";

export default {
  name: "ProcedenciaView",
  components: { grid, Modal, TableComponent, goBack },
  data() {
    return {
      type: "Cuadrilla",
      windowWidth: null,
      mobile: null,
      loading: null,
      loadingCooperativas: null,
      modalActive: null,
      users: null,
      toDelete: null,
      email: null,
      correo: null,
      modalMsg: null,
      successMsg: null,
      tipeLoading: null,
      addModal: null,
      editModal: null,
      toBeEdited: null,
      deleteModal: null,
      toBeDeleted: null,
      name: null,
      cooperativa: null,
      tipo: null,
      columns: null,
      values: null,
      newName: null,
      nombreRecurso: null,
    };
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
    this.loading = true;
  },
  mounted() {
    this.getTable();
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    userCooperativa() {
      return this.$store.state.userCooperativa;
    },

    userRole() {
      return this.$store.state.userRole;
    },
  },
  methods: {
    async getTable() {
      await this.getCooperativas();
      await CuadrillaService.get_table(tokenService.getLocalCompany()).then(
        (res) => {
          this.values = res["rows"];
          this.columns = res["columns"];
        }
      );
      this.loading = false;
    },
    async getCooperativas() {
      try {
        this.loading = true;
        await this.$store.dispatch("get_cooperativas");
        let cooperativas = await this.$store.state.cooperativas;
        this.cooperativas = this.mapResurce(cooperativas);
      } catch (error) {
        console.error("Error fetching metadata:", error);
        // Handle the error appropriately here
      } finally {
        this.loading = false;
      }
    },

    mapResurce(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.nombre,
      }));
    },
    async addData() {
      this.loading = true;
      let cooperativa = this.cooperativa
        ? this.cooperativa.value
        : tokenService.getLocalCompany();
      await CuadrillaService.create(this.nombreRecurso, cooperativa).then(
        () => {
          this.addModal = !this.addModal;
          this.getTable();
        }
      );
    },
    openAddModal() {
      if (this.nombre) {
        this.nombre = null;
      }
      this.addModal = !this.addModal;
    },
    async confirmDeletion(row) {
      this.deleteModal = !this.deleteModal;
      this.loading = true;
      await CuadrillaService.delete(row["id"]).then(() => {
        this.getTable();
      });
    },

    async confirmEdit(row) {
      this.editModal = !this.editModal;
      this.loading = true;
      await CuadrillaService.edit(this.newName, row["id"]).then(() => {
        this.getTable();
      });
    },
    confirmDeleteSelected(data) {
      this.deleteModal = !this.deleteModal;
      this.toBeDeleted = data;
    },

    confirmEditSelected(data) {
      this.editModal = !this.editModal;
      this.toBeEdited = data;
    },
    showModal() {
      this.modalActive = !this.modalActive;
    },

    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 750) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
    },
  },
};
</script>
<style lang="scss" scoped>
li {
  padding-bottom: 1rem;
}
.forecastPanel {
  max-height: 600px !important;
  overflow-y: auto;
}
.btn-act {
  margin-right: 6px !important;
}
form {
  padding: 0 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  @media (min-width: 900px) {
    padding: 0 50px;
  }
  h4 {
    text-align: center;
    margin-bottom: 40px;
    @media (min-width: 900px) {
      font-size: 40px;
    }
  }
  .inputs {
    width: 100%;
    max-width: 350px;
    .input {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 8px;
      input {
        width: 100%;
        border: none;
        background-color: #f2f7f6;
        padding: 4px 4px 4px 30px;
        height: 50px;
        &:focus {
          outline: none;
        }
      }
      .icon {
        width: 12px;
        position: absolute;
        left: 6px;
      }
    }
  }
  .forgot-password {
    text-decoration: none;
    color: #000;
    cursor: pointer;
    font-size: 14px;
    margin: 48px 0 32px;
    border-bottom: 1px solid transparent;
    transition: 0.5s ease all;
    &:hover {
      border-color: #303030;
    }
  }
}
select {
  width: 100%;
  border: none;
  background-color: #f2f7f6;
  padding: 4px 4px 4px 4px;
  height: 50px;
  &:focus {
    outline: none;
  }
}
</style>
