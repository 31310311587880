<template>
  <div>
    <ProgressSpinner
      v-if="this.loading"
      style="width: 50px; height: 50px"
      strokeWidth="8"
      fill="var(--surface-ground)"
      animationDuration=".5s"
      aria-label="Custom ProgressSpinner"
    />
    <div class="tornaguia" v-else>
   
      <div class="grid grid-cols-2 gap-1">
        <!-- procedencia -->
        <div class="flex flex-col">
          <div class="bg-neutral-two rounded p-1">
            <h6 class="text-white">PROCEDENCIA</h6>
          </div>
          <!-- contenido -->
          <div class="p-3">
            <div class="flex items-center justify-around mb-2">
              <div class="flex items-center">
                <h6 class="font-bold mr-2">Departamento:</h6>
                <Dropdown
                  v-model="selectedDepartamento"
                  :options="departamento"
                  optionLabel="label"
                  :placeholder="`Departamento: ${editData['']}`"
                  class="w-full md:w-14rem"
                />
              </div>
              <div class="flex items-center">
                <h6 class="font-bold mr-2">Municipio:</h6>
                <Dropdown
                  v-model="selectedMunicipio"
                  :options="municipio"
                  optionLabel="label"
                  placeholder="Municipio"
                  class="w-full md:w-14rem"
                />
              </div>
            </div>

            <div class="flex flex-col space-y-4">
              <Dropdown
                v-model="selectedYacimiento"
                :options="yacimiento"
                optionLabel="label"
                placeholder="Yacimiento"
                class="w-full md:w-14rem"
              />
              <Dropdown
                v-model="selectedTranca"
                :options="trancas"
                optionLabel="label"
                placeholder="Tranca de Control"
                class="w-full md:w-14rem"
              />
            </div>
          </div>
        </div>
        <!-- Propietario -->
        <div class="flex flex-col">
          <div class="bg-neutral-two rounded p-1">
            <h6 class="text-white uppercase">Propietario del mineral</h6>
          </div>
          <!-- contenido -->
          <div class="p-3">
            <div class="flex items-center mb-2">
              <h6 class="font-bold">Cooperativa Minera Poopo R.L</h6>
            </div>

            <div class="flex flex-col space-y-4">
              <Dropdown
                v-model="selectedDestino"
                :options="destino"
                optionLabel="label"
                placeholder="Empresa Destino"
                class="w-full md:w-14rem"
              />
              <Dropdown
                v-model="selectedContratista"
                :options="contratista"
                optionLabel="label"
                placeholder="Contratista"
                class="w-full md:w-14rem"
              />
              <Dropdown
                v-model="selectedCuadrilla"
                :options="cuadrilla"
                optionLabel="label"
                placeholder="Cuadrilla"
                class="w-full md:w-14rem"
              />
            </div>
          </div>
        </div>
        <!-- medio de transporte -->
        <div class="flex flex-col">
          <div class="bg-neutral-two rounded p-1">
            <h6 class="text-white uppercase">medio de transporte</h6>
          </div>
          <!-- contenido -->
          <div class="p-3">
            <div class="flex flex-col space-y-4">
              <Dropdown
                v-model="selectedTipo"
                :options="tipoVehiculo"
                optionLabel="label"
                placeholder="Tipo"
                class="w-full md:w-14rem"
              />
              <Dropdown
                v-model="selectedMarca"
                :options="marca"
                optionLabel="label"
                placeholder="Marca"
                class="w-full md:w-14rem"
              />
              <Dropdown
                v-model="selectedColor"
                :options="color"
                optionLabel="label"
                placeholder="Color"
                class="w-full md:w-14rem"
              />
              <InputText v-model="inputPlaca" type="text" placeholder="Placa" />
            </div>
          </div>
        </div>

        <div class="flex flex-col">
          <div class="bg-neutral-two rounded p-1">
            <h6 class="text-white uppercase">tipo de material</h6>
          </div>
          <!-- contenido -->
          <div class="p-3">
            <div class="flex flex-col space-y-4">
            
         
              <div class="grid grid-cols-2 gap-1">
                <InputText
                  v-model="inputPeso"
                  type="text"
                  placeholder="Peso en Toneladas"
                />
                <InputText
                  v-model="inputCantidad"
                  type="text"
                  placeholder="Cantidad de Sacos"
                />
              </div>

              <InputText
                v-model="inputLote"
                type="text"
                placeholder="No. de Lote"
              />
            </div>
          </div>
        </div>
      </div>

      <Button @click="EditTornaguia" v-if="!this.loadingAdd"
        >Editar Tornaguia</Button
      >
      <ProgressSpinner
        v-if="this.loadingAdd"
        style="width: 50px; height: 50px"
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
        aria-label="Custom ProgressSpinner"
      />
    </div>
  </div>
</template>
<script>
import tokenService from "@/services/token.service";
import tornaguiaService from "@/services/tornaguia.service";
import moment from "moment";
export default {
  name: "TornaguiaForm",
  props: ["editData"],
  data() {
    return {
      loading: null,
      loadingAdd: null,
      metaData: null,
    };
  },
  mounted() {
    this.getMetaData();
  },
  computed: {
    userName() {
      return tokenService.getLocalName();
    },
    idNumber() {
      return tokenService.getLocalID();
    },
    user() {
      return tokenService.getLocalCompany();
    },
    today() {
      moment.locale("es");
      return moment().format("MM-DD-YYYY");
    },
    userId() {
      return tokenService.getLocalUserID();
    },
    selectedMunicipio: {
      get() {
        return this.$store.state.selectedMunicipio
      },
      set(payload) {
        this.$store.commit('updateMunicipio', payload);
      }
    },
    selectedDepartamento: {
    get() {
      return this.$store.state.selectedDepartamento;
    },
    set(payload) {
      this.$store.commit('updateSelectedDepartamento', payload);
    }
  },
  selectedMaterial: {
    get() {
      return this.$store.state.selectedMaterial;
    },
    set(payload) {
      this.$store.commit('updateSelectedMaterial', payload);
    }
  },
  selectedCuadrilla: {
    get() {
      return this.$store.state.selectedCuadrilla;
    },
    set(payload) {
      this.$store.commit('updateSelectedCuadrilla', payload);
    }
  },
  tipoMaterial: {
    get() {
      return this.$store.state.tipoMaterial;
    },
    set(payload) {
      this.$store.commit('updateTipoMaterial', payload);
    }
  },
  selectedYacimiento: {
    get() {
      return this.$store.state.selectedYacimiento;
    },
    set(payload) {
      this.$store.commit('updateSelectedYacimiento', payload);
    }
  },
  selectedTranca: {
    get() {
      return this.$store.state.selectedTranca;
    },
    set(payload) {
      this.$store.commit('updateSelectedTranca', payload);
    }
  },
  selectedDestino: {
    get() {
      return this.$store.state.selectedDestino;
    },
    set(payload) {
      this.$store.commit('updateSelectedDestino', payload);
    }
  },
  selectedContratista: {
    get() {
      return this.$store.state.selectedContratista;
    },
    set(payload) {
      this.$store.commit('updateSelectedContratista', payload);
    }
  },
  selectedTipo: {
    get() {
      return this.$store.state.selectedTipo;
    },
    set(payload) {
      this.$store.commit('updateSelectedTipo', payload);
    }
  },
  selectedMarca: {
    get() {
      return this.$store.state.selectedMarca;
    },
    set(payload) {
      this.$store.commit('updateSelectedMarca', payload);
    }
  },
  selectedColor: {
    get() {
      return this.$store.state.selectedColor;
    },
    set(payload) {
      this.$store.commit('updateSelectedColor', payload);
    }
  },
  inputPlaca: {
    get() {
      return this.$store.state.inputPlaca;
    },
    set(payload) {
      this.$store.commit('updateInputPlaca', payload);
    }
  },
  inputPeso: {
    get() {
      return this.$store.state.inputPeso;
    },
    set(payload) {
      this.$store.commit('updateInputPeso', payload);
    }
  },
  inputCantidad: {
    get() {
      return this.$store.state.inputCantidad;
    },
    set(payload) {
      this.$store.commit('updateInputCantidad', payload);
    }
  },
  inputLote: {
    get() {
      return this.$store.state.inputLote;
    },
    set(payload) {
      this.$store.commit('updateInputLote', payload);
    }
  }
  },
  methods: {
    async getMetaData() {
      this.loading = true;

      try {
        await this.$store.dispatch("get_meta_data", this.user);
        let meta = await this.$store.state.cooperativa_meta;
        this.yacimiento = this.mapResurce(meta, "yacimiento");
        this.destino = this.mapResurce(meta, "destino");
        this.tipoVehiculo = this.mapResurce(meta, "tipo_vehiculo");
        this.marca = this.mapResurce(meta, "marca_vehiculo");
        this.trancas = this.mapResurce(meta, "tranca");
        this.contratista = this.mapResurce(meta, "contratista");
        this.material = this.mapResurce(meta, "material");
        this.color = this.mapResurce(meta, "color_vehiculo");
        this.departamento = this.mapResurce(meta, "departamento");
        this.municipio = this.mapResurce(meta, "municipio");
        this.tipos_material = this.mapResurce(meta, "tipo_material");
        this.cuadrilla = this.mapResurce(meta, "cuadrilla");
      } catch (error) {
        console.error("Error fetching metadata:", error);
        // Handle the error appropriately here
      } finally {
        this.loading = false;
      }
    },

    async EditTornaguia(){
      await tornaguiaService.edit(
        String(this.inputPeso),
        String(this.inputCantidad),
        String(this.inputLote),
        String(this.inputPlaca),
        this.selectedDepartamento?.value,
        this.selectedMunicipio?.value,
        this.selectedYacimiento?.value,
        this.selectedTranca?.value,
        this.selectedDestino?.value,
        this.selectedContratista?.value,
        this.selectedTipo?.value,
        this.selectedColor?.value,
        this.selectedMarca?.value,
        this.selectedCuadrilla?.value,
        this.editData['id'],
        this.user
        ).then((res) => {
           console.log(res)
           this.$emit('EditDone')
        })
    },

    mapResurce(meta, key) {
      return meta[key].map((item) => ({
        value: item.id,
        label: item.nombre,
      }));
    },

    async createTornaguia() {
      let material = this.selectedMaterial.map((item) => item.value);
      this.loadingAdd = true;
      await tornaguiaService
        .create(
          this.user,
          this.selectedDepartamento?.value,
          this.selectedMunicipio?.value,
          this.selectedYacimiento?.value,
          this.selectedTranca?.value,
          this.selectedDestino?.value,
          this.selectedContratista?.value,
          material[0],
          this.tipoMaterial,
          this.selectedTipo?.value,
          this.selectedColor?.value,
          this.selectedMarca?.value,
          this.selectedCuadrilla?.value,
          this.userId
        )
        .then(() => {
          this.$store.dispatch("get_tornaguias", this.userId);
          this.loadingAdd = false;
        });
    },
  },
};
</script>
<style lang=""></style>
