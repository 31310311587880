<template>
  <div>
    <Dialog
      v-model:visible="deleteModal"
      modal
      header="Confirmacion"
      :style="{ width: '50vw' }"
    >

    <h6>¿Esta seguro que quiere eliminar esta fila: <strong>{{ this.toBeDeleted.id}}? </strong></h6>

      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          @click="this.deleteModal = !this.deleteModal"
          text
        />
        <Button
          label="Si"
          icon="pi pi-check"
          @click="confirmDeletion(this.toBeDeleted)"
          autofocus
        />
      </template>
    </Dialog>
    <!-- Edit Modal -->
    <Dialog
      v-model:visible="editModal"
      modal
      :style="{ width: '85vw' }"
    >
    <ProgressSpinner
      v-if="!this.toBeEdited"
      style="width: 50px; height: 50px"
      strokeWidth="8"
      fill="var(--surface-ground)"
      animationDuration=".5s"
      aria-label="Custom ProgressSpinner"
    />
      <div class="input" v-else>
        <div v-if="toBeEdited.status == 'Cerrado'">
          <div class="flex items-center space-x-2 p-5 rounded bg-yellow-100">
            <i class="pi pi-info-circle" style="font-size: 2.5rem"></i>
            <h4>Esta tornaguia ya no puede ser editada. Si necesita asistencia contacte a soporte</h4>

          </div>
        </div>
        <div v-else>



          <h5>Editando: <strong>{{ toBeEdited.id }}</strong></h5>
          <TornaguiaFormEdit :editData="toBeEdited" @EditDone="this.editTornaguia()"/>

        </div>
      </div>

      <template #footer>
      </template>
    </Dialog>
    <Modal
      v-if="modalActive"
      :modalMessage="this.modalMsg"
      :type="'user'"
      @close-modal="showModal()"
      @confirmDelete="deleteUser()"
    />
    <grid>
      <template #content>
        <ProgressSpinner
      v-if="this.loading"
      style="width: 50px; height: 50px"
      strokeWidth="8"
      fill="var(--surface-ground)"
      animationDuration=".5s"
      aria-label="Custom ProgressSpinner"
    />
    <div v-else>
      <div class="flex justify-between items-center mb-6">
          <h5>Tornaguia</h5>
        </div>
        <hr class="mb-12" />
        <div class="flex flex-col">
          <div>
            <h5 class="text-left mb-4 mt-4">Crear</h5>
            <TornaguiaForm></TornaguiaForm>
          </div>
          <hr class="my-12" />
          <div>
            <div v-if="!this.loading">
              <h5 class="text-left mb-4 mt-4">Historial</h5>
              <div class="flex flex-col mb-4 space-y-2 w-1/5" v-if="this.userRole !== 'user'">
                <Button  @click="descargarTornaguias()"> Descargar tornaguias</Button>
                <span v-if="this.tornaguiaLoading">Su descarga esta siendo processada...</span>
                <span v-if="this.tornaguiaLoadingComplete">Su descarga ha concluido</span>

              </div>
              <TableComponent
                  @openModal="openAddModal"
                  @confirmDelete="confirmDeleteSelected"
                  @confirmEdit="confirmEditSelected"
                  :columns="this.columns"
                  :values="this.rows"
                  :tableTitle="'Manejar Tornaguias'"
          />
          
            </div>
          </div>
        </div>
    </div>
        
      </template>
    </grid>
  </div>
</template>
<script>
import grid from "@/layouts/Layout.vue";
import TableComponent from "@/components/DataComponentGrid.vue";
import Modal from "@/components/Modal";
import TornaguiaForm from "@/components/TornaguiaForm.vue";
import TornaguiaFormEdit from "@/components/TornaguiaFormEdit.vue";
import api from "@/services/api";
import tokenService from "@/services/token.service";
import tornaguiaService from "@/services/tornaguia.service";
export default {
  name: "TornaguiaView",
  components: { grid, Modal, TableComponent, TornaguiaForm, TornaguiaFormEdit },
  data() {
    return {
      windowWidth: null,
      mobile: null,
      deleteModal:null,
      editModal:null,
      loading: null,
      modalActive: null,
      users: null,
      toBeDeleted:null,
      toDelete: null,
      toBeEdited:null,
      email: null,
      correo: null,
      modalMsg: null,
      successMsg: null,
      tipeLoading: null,
      tornaguiaLoading:null,
      tornaguiaLoadingComplete:null,
      role: null,
      roles: [
        { value: "user", tag: "Usuario" },
        { value: "admin", tag: "Administrador" },
      ],
    };
  },
  mounted() {},
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
    this.getTornaguias();
  },
  computed: {
    userCompany() {
      return tokenService.getLocalCompany();
    },
    userRole() {
      return tokenService.getLocalRole();
    },
  },
  methods: {
    async getTornaguias() {
      this.loading = true;

      await tornaguiaService
        .get_table(tokenService.getLocalCompany())
        .then((res) => {
          console.log(res)
          this.rows = res["rows"];
          this.columns = res["columns"];
        });
      this.loading = false;
    },
    async confirmDeletion(row) {
      this.deleteModal = !this.deleteModal;
      this.loading = true;
      await tornaguiaService.delete(row["id"]).then(() => {
        this.getTornaguias();
      });
     
    },

    async confirmEdit(row) {
      this.editModal = !this.editModal;
      this.loading = true;
      console.log(row)

    
    },
    confirmDeleteSelected(data) {
      this.deleteModal = !this.deleteModal;
      this.toBeDeleted = data;

    },

    async descargarTornaguias() {
      this.tornaguiaLoading = true;
      let historica = await this.export_file(
        "/tornaguia/download",
        "tornaguia", 
        this.userCompany
      );
      this.tipeLoading = null;

      return historica;
    },
    export_file(endpoint, file_name, id) {
      api.get(endpoint, {
      params: { cooperativa_id: id },
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
        console.log(url)
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${file_name}.csv`);
        document.body.appendChild(link);
        link.click();
        this.tornaguiaLoading = null;
        this.tornaguiaLoadingComplete= true;
        setTimeout(() => {
          this.tornaguiaLoadingComplete= null;
        }, 2000);


      });
    },

    async confirmEditSelected(data) {
      this.editModal = !this.editModal;
      await tornaguiaService.get_tornaguia(data.id,this.userCompany).then((res) =>{
        this.toBeEdited = res
        this.$store.commit('updateInputPlaca', res['placa_vehiculo']);
        this.$store.commit('updateInputPeso', res['peso_toneladas']);
        this.$store.commit('updateInputCantidad', res['cantidad_sacos']);
        this.$store.commit('updateInputLote', res['no_lote']);
        this.$store.commit('updateSelectedDepartamento', res['departamento']);
        this.$store.commit('updateMunicipio', res['municipio']);
        this.$store.commit('updateSelectedYacimiento', res['yacimiento']);
        this.$store.commit('updateSelectedTranca', res['tranca']);
        this.$store.commit('updateSelectedDestino', res['destino']);
        this.$store.commit('updateSelectedContratista', res['contratista']);
        this.$store.commit('updateSelectedTipo', res['tipo_vehiculo']);
        this.$store.commit('updateSelectedColor', res['color_vehiculo']);
        this.$store.commit('updateSelectedMarca', res['marca_vehiculo']);
        this.$store.commit('updateSelectedCuadrilla', res['cuadrilla']);
      }
      ) ;

    },

    async editTornaguia() {
      this.editModal = !this.editModal;
      this.getTornaguias()
    },
    showModal() {
      this.modalActive = !this.modalActive;
    },

    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 750) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
    },
  },
};
</script>
<style lang="scss" scoped>
li {
  padding-bottom: 1rem;
}
.forecastPanel {
  max-height: 600px !important;
  overflow-y: auto;
}
.btn-act {
  margin-right: 6px !important;
}
form {
  padding: 0 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  @media (min-width: 900px) {
    padding: 0 50px;
  }
  h4 {
    text-align: center;
    margin-bottom: 40px;
    @media (min-width: 900px) {
      font-size: 40px;
    }
  }
  .inputs {
    width: 100%;
    max-width: 350px;
    .input {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 8px;
      input {
        width: 100%;
        border: none;
        background-color: #f2f7f6;
        padding: 4px 4px 4px 30px;
        height: 50px;
        &:focus {
          outline: none;
        }
      }
      .icon {
        width: 12px;
        position: absolute;
        left: 6px;
      }
    }
  }
  .forgot-password {
    text-decoration: none;
    color: #000;
    cursor: pointer;
    font-size: 14px;
    margin: 48px 0 32px;
    border-bottom: 1px solid transparent;
    transition: 0.5s ease all;
    &:hover {
      border-color: #303030;
    }
  }
}
select {
  width: 100%;
  border: none;
  background-color: #f2f7f6;
  padding: 4px 4px 4px 4px;
  height: 50px;
  &:focus {
    outline: none;
  }
}
</style>
