import axiosInstance from "./api";
import TokenService from "./token.service";
// import AuthService from "@/services/auth.service";

const setup = () => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
        config.headers["Access-Control-Allow_Origin"] = "Bearer " + token; // for Spring Boot back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const error_code = err.response.status;
      if (error_code == 401) {
        TokenService.clearUser();
        location.href = "/";
      }
      const originalConfig = err.config;
      if (originalConfig.url === "/") return Promise.reject(err);

      return Promise.reject(err);
    }
  );
};
export default setup;
