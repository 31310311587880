import { createStore } from "vuex";
import TokenService from "@/services/token.service";
import metadataService from "@/services/metadata.service";
import cooperativaService from "@/services/cooperativa.service";
import axios from "axios";
import tornaguiaService from "@/services/tornaguia.service";
export default createStore({
  state: {
    email: null,
    password: null,
    complete: null,
    username: null,
    user: TokenService.getUser(),
    userRole: TokenService.getLocalRole(),
    userCooperativa: TokenService.getLocalCompany(),
    userCompany:null,
    userCompanyName:null,
    cooperativa_meta: null,
    cooperativas: null,
    meta_loaded: null,
    tornaguiaTable: null,
    selectedMunicipio:null,
    selectedDepartamento: null,
    selectedMaterial: null,
    selectedCuadrilla: null,
    tipoMaterial: null,
    selectedYacimiento: null,
    selectedTranca: null,
    selectedDestino: null,
    selectedContratista: null,
    selectedTipo: null,
    selectedMarca: null,
    selectedColor: null,
    inputPlaca: null,
    inputPeso: null,
    inputCantidad: null,
    inputLote: null,
      
    rows: [
      {
        id: 1,
        numero_lote: "112",
        peso: 20,
        cantidad: 20,
        createdAt: "2023-05-23",
        estado: "Pendiente",
      },
      {
        id: 2,
        numero_lote: "113",
        peso: 25,
        cantidad: 25,
        createdAt: "2023-07-26",
        estado: "Pendiente",
      },
    ],
    columns: [
      {
        label: "# Tornaguia",
        field: "id",
      },
      {
        label: "# Lote",
        field: "numero_lote",
      },
      {
        label: "Peso",
        field: "peso",
        type: "number",
      },
      {
        label: "Cantidad",
        field: "cantidad",
        type: "number",
      },
      {
        label: "Fecha",
        field: "createdAt",
        type: "date",
        dateInputFormat: "yyyy-MM-dd",
        dateOutputFormat: "MM/ dd/ yyyy",
      },
      {
        label: "Estado",
        field: "estado",
      },
    ],
  },
  getters: {},
  mutations: {
    updateCooperativaMeta(state, payload) {
      state.cooperativa_meta = payload;
      state.meta_loaded = true;
    },
    updateMunicipio(state,payload){
      state.selectedMunicipio = payload
    },
    updateSelectedDepartamento(state, payload) {
      state.selectedDepartamento = payload;
    },
    updateSelectedMaterial(state, payload) {
      state.selectedMaterial = payload;
    },
    updateUserCompany(state, payload) {
      state.userCompany = payload;
    },
    updateUserCompanyName(state, payload) {
      state.userCompanyName = payload;
    },
    updateSelectedCuadrilla(state, payload) {
      state.selectedCuadrilla = payload;
    },
    updateTipoMaterial(state, payload) {
      state.tipoMaterial = payload;
    },
    updateSelectedYacimiento(state, payload) {
      state.selectedYacimiento = payload;
    },
    updateSelectedTranca(state, payload) {
      state.selectedTranca = payload;
    },
    updateSelectedDestino(state, payload) {
      state.selectedDestino = payload;
    },
    updateSelectedContratista(state, payload) {
      state.selectedContratista = payload;
    },
    updateSelectedTipo(state, payload) {
      state.selectedTipo = payload;
    },
    updateSelectedMarca(state, payload) {
      state.selectedMarca = payload;
    },
    updateSelectedColor(state, payload) {
      state.selectedColor = payload;
    },
    updateInputPlaca(state, payload) {
      state.inputPlaca = payload;
    },
    updateInputPeso(state, payload) {
      state.inputPeso = payload;
    },
    updateInputCantidad(state, payload) {
      state.inputCantidad = payload;
    },
    updateInputLote(state, payload) {
      state.inputLote = payload;
    },

    updateCooperativas(state, payload) {
      state.cooperativas = payload;
    },
    filterYacimiento(state, payload) {
      state.yacimientoValues = state.yacimientoValues.filter(
        (item) => item.id != payload.id
      );
    },
    filterTranca(state, payload) {
      state.trancasValues = state.trancasValues.filter(
        (item) => item.id != payload.id
      );
    },
    addYacimiento(state, payload) {
      state.yacimientoValues.push(payload);
    },
    addTranca(state, payload) {
      state.trancasValues.push(payload);
    },

    filterDestino(state, payload) {
      state.destinoValues = state.destinoValues.filter(
        (item) => item.id != payload.id
      );
    },
    filterContratista(state, payload) {
      state.contratistaValues = state.contratistaValues.filter(
        (item) => item.id != payload.id
      );
    },
    addDestino(state, payload) {
      state.destinoValues.push(payload);
    },
    addContratista(state, payload) {
      state.contratistaValues.push(payload);
    },
    updateTornaguiaTable(state, payload) {
      state.tornaguiaTable = payload;
    },
  },
  actions: {
    async get_meta_data({ commit }, id) {
      await metadataService.get_meta_items(id).then((data) => {
        commit("updateCooperativaMeta", data);
      });
    },
    async get_tornaguias({ commit }, id) {
      await tornaguiaService.get_table(id).then((data) => {
        commit("updateTornaguiaTable", data);
      });
    },

    async get_cooperativas({ commit }) {
      await cooperativaService.get_all().then((data) => {
        commit("updateCooperativas", data);
      });
    },

    async forgot_password({ state, dispatch }, email) {
      let data = {
        params: {
          email: email,
        },
        endpoint: "forgot_password",
      };
      dispatch("postRequest", data);
      state.email = email;
    },
    async reset_password({ state, dispatch }, password) {
      let data = {
        params: {
          new_password: password,
        },
        endpoint: "reset_password",
      };
      let res = dispatch("postRequest", data);
      state.password = true;
      return res;
    },

    async welcome_email({ state, dispatch }, email) {
      let data = {
        params: {
          email: email,
        },
        endpoint: "welcome_email",
      };
      dispatch("postRequest", data);
      state.email = email;
    },

    async postRequest({ state }, data) {
      const token = TokenService.getLocalAccessToken();
      let res = await axios.post(
        `https://sinchiwayra-api.datapaip.com/${data["endpoint"]}`,
        "",
        {
          params: data["params"],
          headers: {
            accept: "application/json",
            authorization: `Bearer ${token}`,
            "content-type": "application/x-www-form-urlencoded",
          },
        }
      );
      state.complete = true;
      return res.status;
    },
  },
  modules: {},
});
