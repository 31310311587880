<template>
  <div>
    <div class="flex">
      <slot name="leftNav" v-if="!mobile">
        <NavBar :NavItems="nav_items"></NavBar>
      </slot>
      <div class="flex-1 max-h-screen overflow-y-auto" v-if="!mobile">
        <div class="py-5 pr-5 lg:pt-[6.5rem] lg:pl-[3.5rem] relative">
          <slot name="content"></slot>
        </div>
      </div>
    </div>

    <div v-if="mobile">
      <slot name="mobilecontent"></slot>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavigationBar.vue";
import MetaService from "@/services/metadata.service";
export default {
  name: "GridMain",
  components: { NavBar },
  data() {
    return {
      windowWidth: null,
      mobile: null,
      nav_items: null,
    };
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
    this.get_menu();
  },
  mounted() {},
  computed: {},
  methods: {
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 850) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },

    async get_menu() {
      await MetaService.get_menu().then((res) => {
        this.nav_items = res;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
