<template>
  <div class="tabs">
    <ul class="tabs_header">
      <li
        v-for="title in tabTitles"
        :key="title"
        :class="{ selected: title == selectedTitle }"
        @click="selectedTitle = title"
      >
        {{ title.title }}
      </li>
    </ul>
    <slot />
  </div>
</template>

<script>
import { ref, provide } from "vue";
export default {
  setup(props, { slots }) {
    const tabTitles = ref(
      slots.default().map((tab) => {
        return {
          title: tab.props.title,
          icon: tab.props.icon,
        };
      })
    );
    const selectedTitle = ref(tabTitles.value[0]);
    provide("selectedTitle", selectedTitle);
    return {
      tabTitles,
      selectedTitle,
    };
  },
};
</script>

<style lang="scss" scoped>
$main-color: #031624;
$secondary-color: rgb(145, 149, 197);
$accent-color: #5c4bda;
$accent-color-two: #e1c940;
$neutral-two: #575777;
$neutral-color: #f2f2f2;
.tabs {
  margin: 0 auto;
  width: 100%;
  .tabs_header {
    margin-bottom: 10px;
    list-style: none;
    padding: 0;
    display: flex;
    li {
      text-align: center;
      padding: 10px 20px;
      margin-right: 10px;
      background-color: $secondary-color;
      border-radius: 5px;
      color: white;
      cursor: pointer;
      transition: 400ms all ease-in-out;
    }
    .selected {
      background-color: $accent-color;
    }
  }
}
</style>
