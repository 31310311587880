import api from "./api";

class AnalyticsService {
  get_sum_sacos_by_cooperativa(id) {
    return api.getData("/analytics/sum_by_cooperativa", {
      params: { cooperativa_id: id },
    });
  }
  get_sum_peso_by_cooperativa(id) {
    return api.getData("/analytics/peso_sum_by_cooperativa", {
      params: { cooperativa_id: id },
    });
  }
  
}
export default new AnalyticsService();
