<template>
  <div v-if="series && series.length">
    <apexchart type="bar" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>

export default {
  name: "BarChartApex",
  props: ["data"],

  data() {
    return {
      chartData: null,
      loading: null,
      series: null,
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
         
        },
        yaxis: {
          min: 0,
        },
        fill: {
          opacity: 1,
        },
      },
    };
  },
  mounted() {
    this.updateChart();
  },
  beforeUpdate() {
    this.updateChart();
  },
  methods: {
    updateChart() {
      console.log(this.data)
      this.series = this.data?.series || [];
      this.chartOptions = {
      ...this.chartOptions,
      xaxis: {
        ...this.chartOptions.xaxis,
        ...(this.data?.xaxis || {})
      }
    };
    },
  },
};
</script>

<style></style>
