import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import PrimeVue from "primevue/config";
import SelectButton from "primevue/selectbutton";
import "./index.css";
import "primevue/resources/themes/tailwind-light/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import AutoComplete from "primevue/autocomplete";
import Button from "primevue/button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dropdown from "primevue/dropdown";
import FileUpload from "primevue/fileupload";
import Sidebar from "primevue/sidebar";
import ScrollPanel from "primevue/scrollpanel";
import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";
import ScrollTop from "primevue/scrolltop";
import OverlayPanel from "primevue/overlaypanel";
import VueApexCharts from "vue3-apexcharts";
import InputSwitch from "primevue/inputswitch";
import Dialog from "primevue/dialog";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import Card from "primevue/card";
import TabMenu from "primevue/tabmenu";
import "vue-good-table-next/dist/vue-good-table-next.css";
import setupInterceptors from "./services/setupIntercepters";
import ProgressSpinner from "primevue/progressspinner";
import MultiSelect from "primevue/multiselect";
import Tooltip from "primevue/tooltip";
import Fieldset from "primevue/fieldset";
import moment from "moment";
import ProgressBar from "primevue/progressbar";
import RadioButton from "primevue/radiobutton";
import JsonCSV from "vue-json-csv";
import VueGoodTablePlugin from "vue-good-table-next";
import { FilterMatchMode } from "primevue/api";
import InputText from "primevue/inputtext";
import Calendar from 'primevue/calendar';

const app = createApp(App);
setupInterceptors(app);

app
  .use(store)
  .use(router)
  .use(PrimeVue, {
    filterMatchModeOptions: {
      text: [
        FilterMatchMode.STARTS_WITH,
        FilterMatchMode.CONTAINS,
        FilterMatchMode.NOT_CONTAINS,
        FilterMatchMode.ENDS_WITH,
        FilterMatchMode.EQUALS,
        FilterMatchMode.NOT_EQUALS,
      ],
      numeric: [
        FilterMatchMode.EQUALS,
        FilterMatchMode.NOT_EQUALS,
        FilterMatchMode.LESS_THAN,
        FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
        FilterMatchMode.GREATER_THAN,
        FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
      ],
      date: [
        FilterMatchMode.DATE_IS,
        FilterMatchMode.DATE_IS_NOT,
        FilterMatchMode.DATE_BEFORE,
        FilterMatchMode.DATE_AFTER,
      ],
    },
    locale: {
      accept: "Aceptar",
      reject: "Rechazar",
      weak: "Debil",
      medium: "Medio",
      strong: "Fuerte",
    },
  })
  .use(moment)
  .use(VueGoodTablePlugin)
  .use(VueApexCharts)
  .use(ToastService)
  .component("downloadCsv", JsonCSV)
  .component("InputText", InputText)
  .component("Fieldset", Fieldset)
  .component('Calendar', Calendar)

  .component("MultiSelect", MultiSelect)
  .component("FileUpload", FileUpload)
  .component("SelectButton", SelectButton)
  .component("Button", Button)
  .component("Sidebar", Sidebar)
  .component("Dropdown", Dropdown)
  .component("AutoComplete", AutoComplete)
  .component("ScrollPanel", ScrollPanel)
  .component("Splitter", Splitter)
  .component("SplitterPanel", SplitterPanel)
  .component("ScrollTop", ScrollTop)
  .component("DataTable", DataTable)
  .component("Column", Column)
  .component("OverlayPanel", OverlayPanel)
  .component("InputSwitch", InputSwitch)
  .component("Dialog", Dialog)
  .component("Toast", Toast)
  .component("TabMenu", TabMenu)
  .component("Card", Card)
  .component("ProgressBar", ProgressBar)
  .component("ProgressSpinner", ProgressSpinner)
  .component("RadioButton", RadioButton)
  .directive("tooltip", Tooltip)

  .mount("#app");
