import api from "./api";

class MetaService {
  get_menu() {
    return api.getData("/meta/menu_items", "");
  }
  get_meta_items(id) {
    return api.getData("/meta/cooperativa_meta", {
      params: { cooperativa_id: id },
    });
  }
}
export default new MetaService();
