<template>
  <div class="form-wrap">
    <form class="register rounded shadow-lg">
      <h4>Iniciar sesión</h4>
      <h6 class="mb-4">Servicio Captura Tornaguia</h6>
      <div class="inputs mb-3">
        <div class="input">
          <input
            type="email"
            placeholder="Correo electronico"
            class="rounded"
            v-model="email"
          />
          <i class="fa-solid fa-envelope icon"></i>
        </div>
        <div class="input">
          <input
            type="password"
            placeholder="Contraseña"
            class="rounded"
            v-model="password"
          />
          <i class="fa-solid fa-lock icon"></i>
        </div>
        <div v-show="error" class="error">{{ this.errorMsg }}</div>
      </div>
      <router-link
        class="text-blue-600 text-center underline"
        :to="{ name: 'forgot' }"
        >¿Olvido Contraseña?</router-link
      >
      <ProgressSpinner
        v-if="this.loading"
        style="width: 50px; height: 50px"
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
        aria-label="Custom ProgressSpinner"
      />

      <button @click.prevent="login" class="btn mt-6">Iniciar sesión</button>
      <br />
    </form>
  </div>
</template>

<script>
import AuthService from "@/services/auth.service";
import TokenService from "@/services/token.service";

export default {
  name: "LoginView",
  data() {
    return {
      email: "",
      password: "",
      error: null,
      errorMsg: "",
      mobile: false,
      loading: null,
    };
  },
  computed: {
    temp() {
      return TokenService.getLocalTemp();
    },
    userRole() {
      return TokenService.getLocalRole();
    },
  },
  mounted() {
    const user = TokenService.getUser();
    if (user && !this.temp) {
      this.$router.push({ name: "home" });
    }
  },
  methods: {
    async login() {
      if (!this.email) {
        this.error = true;
        this.errorMsg = "Please provide an email address";
        return;
      }
      if (!this.password) {
        this.error = true;
        this.errorMsg = "Please provide a password";
        return;
      }
      this.loading = true;
      AuthService.login(this.email, this.password)
        .then((res) => {
          this.$store.state.username = this.email;
          this.$store.state.user = res;
          let cooperativa = res?.cooperativa;
          this.$store.dispatch("get_meta_data", cooperativa);

          if (this.temp) {
            this.$router.push({ name: "reset" });
          } else {
            // eslint-disable-next-line no-constant-condition
            if (this.userRole == "admin" || "super_user") {
              this.$router.push({ name: "admin" });
            } else {
              this.$router.push({ name: "tornaguiaView" });
            }
          }
        })
        .catch((e) => {
          this.loading = false;
          this.error = true;
          const errorMsg =
            e?.response?.data?.detail || "An unknown error occurred";
          this.errorMsg = errorMsg;
          console.log("Error logging in", e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-wrap {
  background-color: #031624;
  overflow: hidden;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 90%;
  @media (min-width: 900px) {
    width: 100%;
  }
  .register {
    max-width: 550px;
    height: 750px;
    box-shadow: rgba(218, 217, 217, 0.16) 0px 10px 36px 0px,
      rgba(218, 217, 217, 0.16) 0px 0px 0px 1px;
    h2 {
      max-width: 350px;
    }
  }
  .login-register {
    margin-bottom: 32px;
    .router-link {
      color: #ff4d3d;
    }
  }
  form {
    padding: 0 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    @media (min-width: 900px) {
      padding: 0 50px;
    }
    h4 {
      text-align: center;
      margin-bottom: 40px;
      @media (min-width: 900px) {
        font-size: 40px;
      }
    }
    .inputs {
      width: 100%;
      max-width: 350px;
      .input {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
        input {
          width: 100%;
          border: none;
          background-color: #f2f7f6;
          padding: 4px 4px 4px 30px;
          height: 50px;
          &:focus {
            outline: none;
          }
        }
        .icon {
          width: 12px;
          position: absolute;
          left: 6px;
        }
      }
    }
    .forgot-password {
      text-decoration: none;
      color: #000;
      cursor: pointer;
      font-size: 14px;
      margin: 48px 0 32px;
      border-bottom: 1px solid transparent;
      transition: 0.5s ease all;
      &:hover {
        border-color: #303030;
      }
    }
  }
  .background {
    flex: 2;
    background-image: url("@/assets/logo.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    @media (max-width: 900px) {
      display: none;
    }
    h1 {
      color: #f2f2f2;
    }
  }
}
.signInBtn {
  background-color: orange;
  transition: 0.5s ease all;
  padding: 6px 24px;
  font-size: 14px;
  font-weight: 400;
}
.error {
  color: #e2000b;
  text-align: center;
}
</style>
