import api from "./api";

class TornaguiaService {
  get_table(id) {
    return api.getData("/tornaguia/get_table_by_user", {
      params: { cooperativa_id: id },
    });
  }
  get_tornaguia(id, cooperativa_id){
    return api.getData("/tornaguia/get_edit", {
      params: { tornaguia_id:id, cooperativa_id: cooperativa_id },
    });
  }
  delete(id) {
    return api.deleteData("/tornaguia/delete", { params: { id: id } });
  }




  create(
    fecha_creacion,
    numero_unico,
    peso_toneladas,
    cantidad_sacos,
    no_lote,
    placa_vehiculo,
    cooperativa_id,
    departamento_id,
    municipio_id,
    yacimiento_id,
    tranca_id,
    destino_id,
    contratista_id,
    material_id,
    tipo_material_id,
    tipo_vehiculo_id,
    color_vehiculo_id,
    marca_vehiculo_id,
    cuadrilla_id,
    user_id
  ) {
    return api.postData("/tornaguia/create", {
      fecha_creacion: fecha_creacion,
      numero_unico: numero_unico,
      peso_toneladas: peso_toneladas,
      cantidad_sacos: cantidad_sacos,
      no_lote: no_lote,
      placa_vehiculo: placa_vehiculo,
      cooperativa_id: cooperativa_id,
      departamento_id: departamento_id,
      municipio_id: municipio_id,
      yacimiento_id: yacimiento_id,
      tranca_id: tranca_id,
      destino_id: destino_id,
      contratista_id: contratista_id,
      material_id: material_id,
      tipo_material_id: tipo_material_id,
      tipo_vehiculo_id: tipo_vehiculo_id,
      color_vehiculo_id: color_vehiculo_id,
      marca_vehiculo_id: marca_vehiculo_id,
      cuadrilla_id: cuadrilla_id,
      user_id: user_id,
    });
  }

  edit(
   
    peso_toneladas,
    cantidad_sacos,
    no_lote,
    placa_vehiculo,
    departamento_id,
    municipio_id,
    yacimiento_id,
    tranca_id,
    destino_id,
    contratista_id,
    tipo_vehiculo_id,
    color_vehiculo_id,
    marca_vehiculo_id,
    cuadrilla_id,
    id, 
    cooperativa_id
  ) {
    return api.putData("/tornaguia/update", {
      peso_toneladas: peso_toneladas,
      cantidad_sacos: cantidad_sacos,
      no_lote: no_lote,
      placa_vehiculo: placa_vehiculo,
      departamento_id: departamento_id,
      municipio_id: municipio_id,
      yacimiento_id: yacimiento_id,
      tranca_id: tranca_id,
      destino_id: destino_id,
      contratista_id: contratista_id,
      tipo_vehiculo_id: tipo_vehiculo_id,
      color_vehiculo_id: color_vehiculo_id,
      marca_vehiculo_id: marca_vehiculo_id,
      cuadrilla_id: cuadrilla_id,
    },
    {params: { id:id, cooperativa_id: cooperativa_id }});
  }
}
export default new TornaguiaService();
