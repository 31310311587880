<template>
  <div>
    <Loading v-if="loading" />

    <grid>
      <template #content>
        <div class="flex justify-between items-center mb-6">
          <h5>Administrar Cooperativa</h5>
        </div>
        <hr class="mb-12" />
        <div class="flex flex-wrap">
          <div class="grid grid-cols-3 gap-4 mb-4">
          <div v-for="(item, index) in pages" :key="index">
            <Fieldset :toggleable="true" :collapsed="true">
              <template #legend>
                <div class="flex items-center text-primary">
                  <span :class="item.icon"></span>
                  <span class="font-bold text-lg ml-2">{{ item.label }}</span>
                </div>
              </template>
              <router-link
                v-for="(item, index) in item.subItems"
                :key="index"
                :to="{ name: item.routeName }"
                class="mb-2 font-medium flex flex-col p-1 rounded bg-gray-100 hover:bg-gray-300"
              >
                {{ item.label }}
                <i :class="item.icon"></i>
              </router-link>
            </Fieldset>
          </div>
        </div>
        </div>
        
        <hr v-if="admin" class="mb-12" />
        <div v-if="admin" class="flex justify-between items-center mb-6">
          <h5>Administrador</h5>
        </div>

        <div v-if="admin" class="grid grid-cols-3 gap-4">
          <div v-for="(item, index) in Adminpages" :key="index">
            <Fieldset :toggleable="true" :collapsed="true">
              <template #legend>
                <div class="flex items-center text-primary">
                  <span :class="item.icon"></span>
                  <span class="font-bold text-lg ml-2">{{ item.label }}</span>
                </div>
              </template>
              <router-link
                v-for="(item, index) in item.subItems"
                :key="index"
                :to="{ name: item.routeName }"
                class="mb-2 font-medium flex flex-col p-1 rounded bg-gray-100 hover:bg-gray-300"
              >
                {{ item.label }}
                <i :class="item.icon"></i>
              </router-link>
            </Fieldset>
          </div>
        </div>
      </template>
    </grid>
  </div>
</template>
<script>
import grid from "@/layouts/Layout.vue";
import Loading from "@/components/Loading.vue";
import tokenService from "@/services/token.service";
export default {
  name: "AdminDashboard",
  components: { grid, Loading },
  data() {
    return {
      windowWidth: null,
      mobile: null,
      loading: null,
      modalActive: null,
      users: null,
      toDelete: null,
      email: null,
      correo: null,
      modalMsg: null,
      successMsg: null,
      tipeLoading: null,
      Adminpages: [
        {
          routeName: "adminUsers",
          label: "Cooperativas",
          icon: "pi pi-building",
          subItems: [
            {
              routeName: "adminCooperativa",
              label: "Administrar Cooperativas",
            },
          ],
        },
        {
          routeName: "adminUsers",
          label: "Usuarios",
          icon: "pi pi-user",
          subItems: [
            {
              routeName: "adminUsers",
              label: "Administrar Usuarios",
            },
          ],
        },
      ],
      pages: [
        {
          routeName: "adminUsers",
          label: "Choferes",
          icon: "pi pi-user",
          subItems: [
            {
              routeName: "adminUsers",
              label: "Administrar Choferes",
            },
          ],
        },
        {
          routeName: "adminYacimiento",
          label: "Procedencias",
          icon: "pi pi-directions",
          subItems: [
            {
              routeName: "adminYacimiento",
              label: "Administrar Yacimientos",
            },
            {
              routeName: "adminTranca",
              label: "Administrar Trancas",
            },
          ],
        },
        {
          routeName: "adminPropietarios",
          label: "Propietarios Mineral",
          icon: "pi pi-lock",
          subItems: [
            {
              routeName: "adminEmpresa",
              label: "Administrar Empresas/Destinos",
            },
            {
              routeName: "adminContratista",
              label: "Administrar Contratistas",
            },
            {
              routeName: "adminCuadrilla",
              label: "Administrar Cuadrillas",
            },
          ],
        },

        {
          routeName: "adminPropietarios",
          label: "Medio de Transporte",
          icon: "pi pi-car",
          subItems: [
            {
              routeName: "adminTipoVehiuclo",
              label: "Administrar Tipos Vehiculos",
            },
            {
              routeName: "adminMarcaVehiculo",
              label: "Administrar Marcas Vehiculos",
            },
            {
              routeName: "adminColorVehiculo",
              label: "Administrar Colores Vehiculos",
            },
          ],
        },

        {
          routeName: "adminPropietarios",
          label: "Materiales",
          icon: "pi pi-box",
          subItems: [
            {
              routeName: "adminTipoMaterial",
              label: "Administrar Tipos Material",
            },
            {
              routeName: "adminCategoriaMaterial",
              label: "Administrar Material",
            },
          ],
        },
      ],
      role: null,
      roles: [
        { value: "user", tag: "Usuario" },
        { value: "admin", tag: "Administrador" },
        { value: "super_user", tag: "Super Usuario" },
      ],
      cooperativas: [
        { value: "1", tag: "1" },
        { value: "2", tag: "2" },
      ],
    };
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
    this.loading = false;
  },
  computed: {
    admin() {
      let role = tokenService.getLocalRole();
      if (role == "admin") {
        return true;
      }
      return false;
    },
  },
  methods: {
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 750) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
    },
  },
};
</script>
<style lang="scss" scoped>
li {
  padding-bottom: 1rem;
}
.forecastPanel {
  max-height: 600px !important;
  overflow-y: auto;
}
.btn-act {
  margin-right: 6px !important;
}

.btn-nav {
  transition: all 0.3s ease-in-out;
  &:hover {
    scale: 1.01;
  }
}
form {
  padding: 0 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  @media (min-width: 900px) {
    padding: 0 50px;
  }
  h4 {
    text-align: center;
    margin-bottom: 40px;
    @media (min-width: 900px) {
      font-size: 40px;
    }
  }
  .inputs {
    width: 100%;
    max-width: 350px;
    .input {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 8px;
      input {
        width: 100%;
        border: none;
        background-color: #f2f7f6;
        padding: 4px 4px 4px 30px;
        height: 50px;
        &:focus {
          outline: none;
        }
      }
      .icon {
        width: 12px;
        position: absolute;
        left: 6px;
      }
    }
  }
  .forgot-password {
    text-decoration: none;
    color: #000;
    cursor: pointer;
    font-size: 14px;
    margin: 48px 0 32px;
    border-bottom: 1px solid transparent;
    transition: 0.5s ease all;
    &:hover {
      border-color: #303030;
    }
  }
}

select {
  width: 100%;
  border: none;
  background-color: #f2f7f6;
  padding: 4px 4px 4px 4px;
  height: 50px;
  &:focus {
    outline: none;
  }
}
</style>
