<template>
  <div>
    <Loading v-if="loading" />
    <Modal
      v-if="modalActive"
      :modalMessage="this.modalMsg"
      :type="'user'"
      @close-modal="showModal()"
      @confirmDelete="deleteUser()"
    />
    <grid>
      <template #content>
        <goBack />
     
        <div class="flex justify-between items-center mb-6">
          <h5>Administrador - Usuarios</h5>
        </div>
        <hr class="mb-12" />
        <TabsWrapper>
          <Tab title="Usuarios">
            <div class="flex flex-col">
              <h6 class="text-left mb-2">Manejar usuarios</h6>
              <hr class="mb-6" />
              <div
                class="flex p-3 items-center"
                v-for="user in users"
                :key="user.id"
              >
                <div class="w-1/8 mr-4">
                  <i class="pi pi-user"></i>
                </div>
                <div class="flex justify-between w-full">
                  <div class="flex flex-col">
                    <div class="flex mb-1">
                      <p class="font-semibold mr-1">Nombre:</p>
                      <p>{{ user.full_name }}</p>
                    </div>
                    <div class="flex mb-1">
                      <p class="font-semibold mr-1">Correo:</p>
                      <p>{{ user.email }}</p>
                    </div>
                    <div class="flex mb-1">
                      <p class="font-semibold mr-1">Rol:</p>
                      <p>{{ user.role }}</p>
                    </div>
                    <div class="flex mb-1">
                      <p class="font-semibold mr-1">Cooperativa:</p>
                      <p>{{ user.cooperativa_rel["nombre"] }}</p>
                    </div>
                  </div>
                  <div class="flex items-center">
                    <Button
                      label="Cambiar contraseña"
                      icon="'pi pi-spin pi-spinner'"
                      iconPos="right"
                      class="btn-act p-button-info"
                      @click="sendResetPassword(user.email)"
                    />
                    <Button
                      label="Editar usuario"
                      icon="pi pi-pencil"
                      iconPos="right"
                      severity="warning"
                      class="btn-act p-button-warning"
                    />

                    <Button
                      label="Eliminar"
                      icon="pi pi-trash"
                      iconPos="right"
                      class="p-button-danger"
                      severity="danger"
                      @click="deleteThis(user)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Tab>
          <Tab title="Agregar">
            <div class="flex flex-col">
              <h6 class="text-left mb-2">Agregar usuario</h6>
              <hr class="mb-6" />
              <form class="rounded">
                <div class="inputs">
                  <div class="input">
                    <div class="flex flex-col w-full">
                      <label for="email">Correo electronico:</label>
                      <input type="email" class="rounded" v-model="correo" />
                    </div>
                  </div>
                  <div class="input">
                    <div class="flex flex-col w-full">
                      <label for="name">Nombre completo:</label>
                      <input type="text" class="rounded" v-model="name" />
                    </div>
                  </div>
                  <div class="input">
                    <div class="flex flex-col w-full">
                      <label for="role">Rol/permisos:</label>
                      <select id="role" v-model="role" class="select rounded">
                        <option value="" disabled selected>
                          Seleccionar rol
                        </option>
                        <option
                          v-for="(rol, index) in roles"
                          :key="index"
                          :value="rol.value"
                        >
                          {{ rol.tag }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="input">
                    <div class="flex flex-col w-full">
                      <label for="role">Cooperativa:</label>
                      <select
                        id="role"
                        v-model="cooperativa"
                        class="select rounded"
                      >
                        <option value="" disabled selected>
                          Seleccionar cooperativa
                        </option>
                        <option
                          v-for="(rol, index) in cooperativas"
                          :key="index"
                          :value="rol.value"
                        >
                          {{ rol.tag }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div v-show="successMsg" class="error">
                    {{ this.errorMsg }}
                  </div>
                </div>
                <button @click.prevent="this.createUser()" class="btn mt-6">
                  crear cuenta
                  <i
                    class="pi pi-spin pi-spinner"
                    v-if="this.tipeLoading === 'cuenta'"
                  ></i>
                  <i
                    class="pi pi-check"
                    v-if="this.tipeLoading === 'check'"
                  ></i>
                </button>
                <br />
              </form>
            </div>
          </Tab>
        </TabsWrapper>
      </template>
    </grid>
  </div>
</template>
<script>
import grid from "@/layouts/Layout.vue";
import UserService from "@/services/user.service";
import Loading from "@/components/Loading.vue";
import TabsWrapper from "@/components/TabsWrapper.vue";
import Tab from "@/components/TabComponent.vue";
import Modal from "@/components/Modal";
import goBack from "@/components/resources/GoBack.vue";
export default {
  name: "PlaygroundView",
  components: { grid, Loading, TabsWrapper, Tab, Modal, goBack },
  data() {
    return {
      windowWidth: null,
      mobile: null,
      loading: null,
      modalActive: null,
      users: null,
      toDelete: null,
      email: null,
      correo: null,
      modalMsg: null,
      successMsg: null,
      tipeLoading: null,
      name: null,
      role: null,
      cooperativa: null,
      roles: [
        { value: "user", tag: "Usuario" },
        { value: "admin", tag: "Administrador" },
        { value: "super_user", tag: "Super Usuario" },
      ],
      cooperativas: null,
    };
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
    this.get_users();
  },
  methods: {
    generatePassword(length) {
      const chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";

      let password = "";
      for (let i = 0; i < length; i++) {
        // Choose a random character from the chars string
        const randomIndex = Math.floor(Math.random() * chars.length);
        password += chars[randomIndex];
      }
      return `${password}P90P!#`;
    },
    showModal() {
      this.modalActive = !this.modalActive;
    },
    deleteThis(id) {
      this.toDelete = id;
      this.modalMsg =
        "Una vez eliminado, este usuario no tendra mas acceso a la plataforma";
      this.showModal();
    },
    resetPassword(email) {
      this.email = email;
    },
    async createUser() {
      let password = this.generatePassword(12);
      this.tipeLoading = "cuenta";
      console.log(this.role)

      await UserService.create(
        this.correo,
        password,
        this.role,
        this.name,
        this.cooperativa
      ).then(() => {
        this.tipeLoading = "check";

        this.successMsg = "Usuario creado exitosamente";
        this.get_users();
        setTimeout(() => {
          this.tipeLoading = null;

          this.$store.dispatch("welcome_email", this.correo);
        }, 8000);
      });
    },
    async sendResetPassword(email) {
      await this.$store.dispatch("forgot_password", email).then(() => {
        this.showModal();
        this.modalMsg = `Correo enviado exitosamente a: ${email}`;
        setTimeout(() => {
          this.showModal();
        }, 2000);
      });
    },
    async deleteUser() {
      await UserService.delete(this.toDelete.id).then(() => {
        this.users.pop(this.toDelete);
        this.showModal();
      });
    },
    async get_users() {
      await this.getCooperativas();
      await UserService.get_all().then((res) => {
        this.users = res;
      });
    },

    async getCooperativas() {
      try {
        this.loading = true;
        await this.$store.dispatch("get_cooperativas");
        let cooperativas = await this.$store.state.cooperativas;
        this.cooperativas = this.mapResurce(cooperativas);
      } catch (error) {
        console.error("Error fetching metadata:", error);
        // Handle the error appropriately here
      } finally {
        this.loading = false;
      }
    },

    mapResurce(data) {
      return data.map((item) => ({
        value: item.id,
        tag: item.nombre,
      }));
    },

    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 750) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
    },
  },
};
</script>
<style lang="scss" scoped>
li {
  padding-bottom: 1rem;
}
.forecastPanel {
  max-height: 600px !important;
  overflow-y: auto;
}
.btn-act {
  margin-right: 6px !important;
}
form {
  padding: 0 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  @media (min-width: 900px) {
    padding: 0 50px;
  }
  h4 {
    text-align: center;
    margin-bottom: 40px;
    @media (min-width: 900px) {
      font-size: 40px;
    }
  }
  .inputs {
    width: 100%;
    max-width: 350px;
    .input {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 8px;
      input {
        width: 100%;
        border: none;
        background-color: #f2f7f6;
        padding: 4px 4px 4px 30px;
        height: 50px;
        &:focus {
          outline: none;
        }
      }
      .icon {
        width: 12px;
        position: absolute;
        left: 6px;
      }
    }
  }
  .forgot-password {
    text-decoration: none;
    color: #000;
    cursor: pointer;
    font-size: 14px;
    margin: 48px 0 32px;
    border-bottom: 1px solid transparent;
    transition: 0.5s ease all;
    &:hover {
      border-color: #303030;
    }
  }
}
select {
  width: 100%;
  border: none;
  background-color: #f2f7f6;
  padding: 4px 4px 4px 4px;
  height: 50px;
  &:focus {
    outline: none;
  }
}
</style>
