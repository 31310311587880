import api from "./api";
class UserService {
  get(id, cooperativa_id) {
    return api.getData("/user/get", {
      params: { id: id, cooperativa_id: cooperativa_id },
    });
  }
  get_all() {
    return api.getData("/user/get_all");
  }
  delete(id) {
    return api.deleteData("/user/delete", { params: { id: id } });
  }
  create(email, password, role, name, cooperativa) {
    return api.postData("/user/create", {
      email: email,
      password: password,
      full_name: name,
      role: role,
      cooperativa: cooperativa,
      id_number: "",
    });
  }
}
export default new UserService();
