<template>
  <div>
    <grid>
      <template #content>
        <ProgressSpinner
          v-if="this.loading"
          style="width: 50px; height: 50px"
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".5s"
          aria-label="Custom ProgressSpinner"
        />
        <div v-else>
          <div class="flex justify-between items-center mb-6">
          <h5>Analisis</h5>
        </div>
        <hr class="mb-12" />
        <div
          class="flex w-full rounded bg-white shadow-lg p-3 py-10"
        >

          <div class="flex flex-col w-full space-y-4">
            <h6>Cantidad de sacos por cooperativa</h6>

            <div>
              <Dropdown
              v-if="this.cooperativas"
              v-model="series_by"
              :options="cooperativas"
              optionLabel="label"
              placeholder="Cooperativa"
              class="w-full md:w-14rem"
            @change="getData"

            />
        
            <Button v-if="series_by" @click="resetChart()" icon="pi pi-replay" aria-label="Submit"></Button>
        
            </div>
            
            <div class="flex items-end class mb-4 space-x-4">
              <div class="flex flex-col space-y-2">
              <label for="date">Fecha desde</label>
              <div class="flex flex-col items-center p-3 rounded border">
              <input type="date" v-model="fromDate">
              </div>
              </div>
              <div class="flex flex-col space-y-2">
              <label for="date">Fecha hasta</label>
              <div class="flex flex-col items-center p-3 rounded border">
              <input type="date" v-model="toDate">
              </div>
              </div>
              <div class="flex items-center space-x-2">
              <Button v-if="fromDate || toDate" @click="filterChart()" icon="pi pi-check" label="Aplicar"></Button>
              <Button v-if="fromDate || toDate" @click="resetChart()" icon="pi pi-replay" ></Button>

              </div>
              <span class="text-red-600" v-if="error">{{ this.errorMessage }}</span>
           
            </div>
            
          <BarChart :data="this.data"/>

          </div>

        
        </div>
        </div>
        
      </template>
    </grid>
  </div>
</template>
<script>
import grid from "@/layouts/Layout.vue";
import analyticsService from "@/services/analytics.service";
import BarChart from "@/charts/BarChart.vue";
export default {
  name: "PlaygroundView",
  components: { grid, BarChart  },
  data() {
    return {
      windowWidth: null,
      mobile: null,
      data:null,
      data_peso:null,
      loading: null,
      modalActive: null,
      cooperativas:[],
      modalMsg: null,
      successMsg: null,
      series_by:null,
      series_peso_by:null,
      fromDate:null,
      toDate:null,
      errorMessage:null,
      error:null,
      options: {
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },
      series: [
        {
          name: "series-1",
          data: [30, 40, 45, 50, 49, 60, 70, 91],
        },
      ],
    };
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
    this.loading = true;
  },
  mounted() {
    this.getData();
    this.getPesoData()
  },
 
  methods: {
    resetChart() {
      this.series_by = null,
      this.getData()

    },
    async getData() {
      let id = this.series_by? this.series_by.value : null
      await analyticsService.get_sum_sacos_by_cooperativa(id).then((res) => {
        this.data = res,
        this.cooperativas = res.cooperativas
      });
    },
    async getPesoData() {
      console.log("getting peso data");
      let id = this.series_peso_by? this.series_peso_by.value : null
      await analyticsService.get_sum_peso_by_cooperativa(id).then((res) => {
        this.data_peso = res
      });
      this.loading = false
    },

    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 750) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
    },
  },
};
</script>
<style lang="scss" scoped>
li {
  padding-bottom: 1rem;
}
.forecastPanel {
  max-height: 600px !important;
  overflow-y: auto;
}
.btn-act {
  margin-right: 6px !important;
}
form {
  padding: 0 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  @media (min-width: 900px) {
    padding: 0 50px;
  }
  h4 {
    text-align: center;
    margin-bottom: 40px;
    @media (min-width: 900px) {
      font-size: 40px;
    }
  }
  .inputs {
    width: 100%;
    max-width: 350px;
    .input {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 8px;
      input {
        width: 100%;
        border: none;
        background-color: #f2f7f6;
        padding: 4px 4px 4px 30px;
        height: 50px;
        &:focus {
          outline: none;
        }
      }
      .icon {
        width: 12px;
        position: absolute;
        left: 6px;
      }
    }
  }
  .forgot-password {
    text-decoration: none;
    color: #000;
    cursor: pointer;
    font-size: 14px;
    margin: 48px 0 32px;
    border-bottom: 1px solid transparent;
    transition: 0.5s ease all;
    &:hover {
      border-color: #303030;
    }
  }
}
select {
  width: 100%;
  border: none;
  background-color: #f2f7f6;
  padding: 4px 4px 4px 4px;
  height: 50px;
  &:focus {
    outline: none;
  }
}
</style>
