<template>
  <div class="form-wrap">
    <form class="register rounded shadow-lg">
      <div class="flex"></div>
      <h4>Olvido contraseña</h4>
      <h6 class="mb-4">
        Antes de continuar, por favor Ingrese su correo electronico
      </h6>
      <div class="inputs">
        <div class="input">
          <input
            type="text"
            placeholder="Correo Electronico"
            class="rounded"
            v-model="correo"
          />
          <i class="fa-solid fa-lock icon"></i>
        </div>

        <div v-show="error" class="error">{{ this.errorMsg }}</div>
      </div>

      <router-link
        class="text-blue-600 text-center underline"
        :to="{ name: 'home' }"
        >Iniciar Sesion</router-link
      >
      <ProgressSpinner
        v-if="this.loading"
        style="width: 50px; height: 50px"
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
        aria-label="Custom ProgressSpinner"
      />
      <button @click.prevent="sendResetPassword" class="btn mt-6">
        Continuar
      </button>
      <Dialog
        v-model:visible="modalActive"
        modal
        header="Contraseña temporal enviada"
        :style="{ width: '50vw' }"
      >
        <p>
          Por favor revise su bandeja de entrada, un correo fue enviado con una
          contraseña temporal.
        </p>
      </Dialog>
    </form>
  </div>
</template>

<script>
export default {
  name: "ForgotView",
  data() {
    return {
      correo: "",
      error: null,
      errorMsg: "",
      mobile: false,
      modalActive: null,
      loading: null,
    };
  },

  mounted() {},
  methods: {
    async sendResetPassword() {
      if (this.correo) {
        await this.$store.dispatch("forgot_password", this.correo).then(() => {
          this.loading = true;
          setTimeout(() => {
            this.showModal();
            this.loading = false;
          }, 1000);
          this.modalMsg = `Correo enviado exitosamente a: ${this.correo}`;
          setTimeout(() => {
            this.showModal();
          }, 2000);
          setTimeout(() => {
            this.$router.push({ name: "home" });
          }, 3500);
        });
      } else {
        this.error = true;
        this.errorMsg = "Por favor ingrese un correo electronico";
      }
    },

    showModal() {
      this.modalActive = !this.modalActive;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-wrap {
  background-color: #031624;
  overflow: hidden;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 90%;
  @media (min-width: 900px) {
    width: 100%;
  }
  .register {
    max-width: 550px;
    height: 750px;
    box-shadow: rgba(218, 217, 217, 0.16) 0px 10px 36px 0px,
      rgba(218, 217, 217, 0.16) 0px 0px 0px 1px;
    h2 {
      max-width: 350px;
    }
  }
  .login-register {
    margin-bottom: 32px;
    .router-link {
      color: #ff4d3d;
    }
  }
  form {
    padding: 0 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    @media (min-width: 900px) {
      padding: 0 50px;
    }
    h4 {
      text-align: center;
      margin-bottom: 40px;
      @media (min-width: 900px) {
        font-size: 40px;
      }
    }
    .inputs {
      width: 100%;
      max-width: 350px;
      .input {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
        input {
          width: 100%;
          border: none;
          background-color: #f2f7f6;
          padding: 4px 4px 4px 30px;
          height: 50px;
          &:focus {
            outline: none;
          }
        }
        .icon {
          width: 12px;
          position: absolute;
          left: 6px;
        }
      }
    }
    .forgot-password {
      text-decoration: none;
      color: #000;
      cursor: pointer;
      font-size: 14px;
      margin: 48px 0 32px;
      border-bottom: 1px solid transparent;
      transition: 0.5s ease all;
      &:hover {
        border-color: #303030;
      }
    }
  }
  .background {
    flex: 2;
    background-image: url("@/assets/logo.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    @media (max-width: 900px) {
      display: none;
    }
    h1 {
      color: #f2f2f2;
    }
  }
}
.signInBtn {
  background-color: orange;
  transition: 0.5s ease all;
  padding: 6px 24px;
  font-size: 14px;
  font-weight: 400;
}
.error {
  color: #e2000b;
  text-align: center;
}
</style>
