import api from "./api";

class MaterialService {
  get_table(id) {
    return api.getData("/material/table", {
      params: { cooperativa_id: id },
    });
  }
  delete(id) {
    return api.deleteData("/material/delete", { params: { id: id } });
  }
  create(nombre, cooperativa_id) {
    return api.postData("/material/create", {
      nombre: nombre,
      cooperativa_id: cooperativa_id,
    });
  }

  edit(nombre, id) {
    return api.putData(
      "/material/update",
      {
        nombre: nombre,
      },
      { params: { id: id } }
    );
  }
}
export default new MaterialService();
