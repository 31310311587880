<template>
  <DataTable
    v-model:selection="selectedRows"
    :value="values"
    tableStyle="min-width: 100%"
    paginator
    :rows="10"
  >
    <template #header>
      <div class="flex flex-wrap align-items-center justify-between gap-2">
        <span class="text-xl text-900 font-bold">{{ this.tableTitle }}</span>
        <div class="flex space-x-4">
          <Button
            label="Eliminar"
            icon="pi pi-trash"
            severity="danger"
            class="btnCustom"
            @click="confirmDeleteSelected"
            :disabled="!this.selectedRows"
          />
          <Button
            label="Editar"
            icon="pi pi-pencil"
            @click="confirmEditSelected"
            :disabled="!this.selectedRows"
          />
          <Button
          v-if="!type"
            icon="pi pi-plus"
            rounded
            raised
            @click="openAddModal"
          ></Button>
        </div>
      </div>
    </template>
    <Column selectionMode="single" headerStyle="width: 3rem"></Column>
    <Column
      v-for="col of columns"
      :key="col.field"
      :field="col.field"
      :header="col.header"
      :sortable="true"
      :dataType="col.dataType"
    >
    </Column>
  </DataTable>
</template>
<script>
export default {
  props: ["columns", "values", "tableTitle","type"],
  name: "DataTableComponent",
  data() {
    return {
      selectedRows: null,
    };
  },
  methods: {
    openAddModal() {
      this.$emit("openModal", this.tableTitle);
    },
    confirmDeleteSelected() {
      this.$emit("confirmDelete", this.selectedRows);
    },
    confirmEditSelected() {
      this.$emit("confirmEdit", this.selectedRows);
    },
  },
};
</script>
<style lang=""></style>
