<template>
  <div class="m-6">
    <Button icon="pi pi-angle-left" aria-label="Atras" @click="goBack()" />
  </div>
</template>
<script>
export default {
  name: "GoBack",
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang=""></style>
