import api from "./api";

class TrancaService {
  get_table(id) {
    return api.getData("/tranca/table", {
      params: { cooperativa_id: id },
    });
  }
  delete(id) {
    return api.deleteData("/tranca/delete", { params: { id: id } });
  }
  create(nombre, cooperativa_id) {
    return api.postData("/tranca/create", {
      nombre: nombre,
      cooperativa_id: cooperativa_id,
    });
  }

  edit(nombre, id) {
    return api.putData(
      "/tranca/update",
      {
        nombre: nombre,
      },
      { params: { id: id } }
    );
  }
}
export default new TrancaService();
