import api from "./api";

class CooperativaService {
  get_all() {
    return api.getData("/cooperativa/get_all");
  }
  get_table() {
    return api.getData("/cooperativa/table");
  }
  delete(id) {
    return api.deleteData("/cooperativa/delete", { params: { id: id } });
  }
  create(nombre, personeria_juridica, ficha_registro, nit, cel, ciudad) {
    return api.postData("/cooperativa/create", {
      nombre: nombre,
      personeria_juridica: personeria_juridica,
      ficha_registro: ficha_registro,
      nit: nit,
      cel: cel,
      ciudad: ciudad,
    });
  }

  edit(personeria_juridica, ficha_registro, nit, cel, ciudad, id) {
    return api.putData(
      "/cooperativa/update",
      {
        personeria_juridica: personeria_juridica,
        ficha_registro: ficha_registro,
        nit: nit,
        cel: cel,
        ciudad: ciudad,
      },
      { params: { id: id } }
    );
  }
}
export default new CooperativaService();
