import axios from "axios";
import unNestData from "@/utils/unnest";

console.log("Backend API URL is ", process.env.VUE_APP_API);
const instance = axios.create({
  baseURL: process.env.VUE_APP_API || "http://localhost:8021/",
  headers: {
    "Content-Type": "application/json",
  },
});

instance.putData = (url, data, config) => {
  return instance.put(url, data, config).then(unNestData);
};

instance.postData = (url, data, config) => {
  return instance.post(url, data, config).then(unNestData);
};
instance.deleteData = (url, data, config) => {
  return instance.delete(url, data, config).then(unNestData);
};

instance.getData = (url, config) => {
  return instance.get(url, config).then(unNestData);
};

export default instance;
